import React, { useState, useImperativeHandle } from 'react'
import Portal from './Portal'
import ReactModal from 'react-modal'
import deepmerge from 'deepmerge'
import { isMobile } from 'react-device-detect'
import { mq, styled, typography, colors } from '../styles'
import arrowRight from '../images/arrowRight.png'
import arrowLeft from '../images/arrowLeft.png'

ReactModal.setAppElement('#portal')

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(245, 238, 232, 0.95)',
    zIndex: 12,
  },
  content: {
    margin: 'auto',
    width: isMobile ? 'calc(100vw - 30px)' : 600,
    borderRadius: 15,
    border: 'solid 1px black',
    padding: 0,
    display: 'flex',
    overflow: 'visible',
    top: isMobile ? 15 : 40,
    left: isMobile ? 15 : 40,
    right: isMobile ? 15 : 40,
    bottom: isMobile ? 15 : 40,
  },
}

interface ModalProps {
  style?: ReactModal.Styles
  children: React.ReactNode
  onLeft?: () => void
  onRight?: () => void
}

export type ModalHandle = {
  openModal?: () => void
  closeModal?: () => void
}

const Modal: React.ForwardRefRenderFunction<ModalHandle, ModalProps> = (
  props,
  forwardedRef,
) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)

  useImperativeHandle(forwardedRef, () => {
    return {
      openModal: () => handleOpen(),
      closeModal: () => handleClose(),
    }
  })

  const handleOpen = () => {
    setModalIsOpen(true)
  }

  const handleClose = () => {
    setModalIsOpen(false)
  }

  return (
    <Portal>
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={handleClose}
        style={
          props.style ? deepmerge(customStyles, props.style) : customStyles
        }>
        <Container>
          {props.children}
          <CloseButton onClick={handleClose}>X</CloseButton>
        </Container>

        <LeftButton onClick={props.onLeft}>
          <ArrowImage src={arrowLeft} alt={'left arrow'} />
        </LeftButton>
        <RightButton onClick={props.onRight}>
          <ArrowImage src={arrowRight} alt={'right arrow'} />
        </RightButton>
      </ReactModal>
    </Portal>
  )
}

const Container = styled.div({
  display: 'flex',
  overflow: 'auto',
  borderRadius: 'inherit',
})

const CloseButton = styled.button(
  typography.tinyMono,
  {
    position: 'absolute',

    width: 20,
    height: 20,
    borderRadius: 20,
  },
  mq({
    right: 10,
    top: 10,
  }),
)

const LeftButton = styled.button({
  position: 'absolute',
  left: -100,
  top: '50%',
})

const RightButton = styled.button({
  position: 'absolute',
  right: -100,
  top: '50%',
})

const ArrowImage = styled.img({
  width: 40,
})

export default React.forwardRef(Modal)
