import React, {
  FC,
  useState,
  useMemo,
  useCallback,
  Fragment,
  useEffect,
  useRef,
} from 'react'
import { graphql, PageProps } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic'
import moment from 'moment-timezone'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css/bundle'
import { Global, css } from '@emotion/react'
import Truncate from 'react-truncate'
import { isMobile } from 'react-device-detect'
import { useSwipeable } from 'react-swipeable'
import { Waypoint } from 'react-waypoint'
import { Element, scroller } from 'react-scroll'

import Layout from '../components/Layout'
import '../components/base.css'
import {
  ArchiveQuery,
  SanityEvent,
  SanityEventPost,
  SanitySlug,
  Maybe,
  SanityImageAsset,
} from '../../graphql-types'
import extractGlobals from '../lib/extractGlobals'
import { mq, spaces, styled, typography, colors } from '../styles'
import { getSanitySrcSet } from '../components/SanityFluidImage'
import locationIcon from '../images/locationIcon.png'
import placesIcon from '../images/PlacesIcon.png'
import sunsetIcon from '../images/sunset.png'
import Modal, { ModalHandle } from '../components/Modal'
import { InternalLinkButton } from '../components/Button'
import {
  Season,
  EventType,
  groupEventsByMonthForSeason,
  backgroundColorByType,
} from '../lib/EventUtils'

const GUTTER_SIZE = 25

type SeasonSlideType = { [key: number]: Season }
const seasonSlide: SeasonSlideType = {
  0: Season.FALL_2021,
  1: Season.SUMMER_2021,
  2: Season.SPRING_2021,
  3: Season.WINTER_2021,
}

type SeasonEventsSortedType = {
  name: Season
  title: string
  index: number
  events: EventType[][]
}

type SanityEventPostType = Pick<SanityEventPost, 'name' | 'excerpt'> & {
  event?: Maybe<Pick<SanityEvent, '_id' | 'type' | 'startTime' | 'location'>>
  slug?: Maybe<Pick<SanitySlug, 'current'>>
  mainImage?: Maybe<{
    asset?: Maybe<Pick<SanityImageAsset, 'gatsbyImageData' | 'url'>>
  }>
}

type EventPostType = {
  [key: string]: SanityEventPostType
}

const Archive: FC<PageProps<ArchiveQuery>> = ({ data }) => {
  const { meta, footer } = extractGlobals(data)
  const allSanityEventPost = data && data.allSanityEventPost

  const [selectedSeasonIndex, setSelectedSeasonIndex] = useState(0)

  const [ready, setReady] = useState(false)

  const swiperRef = React.useRef(null)
  const [surpassWaypoint, setSurpassWaypoint] = useState(false)
  const [isDraggingSeasonTab, setIsDraggingSeasonTab] = useState(false)

  const [sortedArrayData, sortedFlatArrayData] = useMemo(() => {
    const winter2021Events = groupEventsByMonthForSeason(
      data.winter2021Events.nodes,
    )
    const spring2021Events = groupEventsByMonthForSeason(
      data.spring2021Events.nodes,
    )
    const summber2021Events = groupEventsByMonthForSeason(
      data.summer2021Events.nodes,
    )
    const fall2021Events = groupEventsByMonthForSeason(
      data.fall2021Events.nodes,
    )

    const arrayData = [
      {
        name: Season.FALL_2021,
        events: fall2021Events,
        title: 'Fall 2021',
        index: 0,
      },
      {
        name: Season.SUMMER_2021,
        events: summber2021Events,
        title: 'Summer 2021',
        index: 1,
      },
      {
        name: Season.SPRING_2021,
        events: spring2021Events,
        title: 'Spring 2021',
        index: 2,
      },
      {
        name: Season.WINTER_2021,
        events: winter2021Events,
        title: 'Winter 2021',
        index: 3,
      },
    ]

    const flatData = fall2021Events
      .flat()
      .concat(summber2021Events.flat())
      .concat(spring2021Events.flat())
      .concat(winter2021Events.flat())

    return [arrayData, flatData]
  }, [data]) as [SeasonEventsSortedType[], EventType[]]

  const eventPosts = useMemo(() => {
    let allPosts = {} as EventPostType
    allSanityEventPost.nodes.map((node) => {
      if (node.event && node.event?._id && node.slug) {
        allPosts[node.event?._id] = node
      }
    })
    return allPosts
  }, [allSanityEventPost])

  const modalRef1 = useRef<ModalHandle>(null)
  const [modalEvent, setModalEvent] = useState<EventType>()

  // Necessary for truncate libray to work
  useEffect(() => {
    if (ready) return
    document.fonts.ready.then(function () {
      setReady(true)
    })
  })

  // Swiper slider related functions
  const handleSwiperTouchStart = useCallback((swiper) => {
    setIsDraggingSeasonTab(true)
  }, [])

  const handleSwiperTouchEnd = useCallback((swiper) => {
    setIsDraggingSeasonTab(false)
  }, [])

  const handleSwiperSlideChange = useCallback(
    (swiper) => {
      const activeIndex = swiper.activeIndex as number
      setSelectedSeasonIndex(activeIndex)

      if (!isDraggingSeasonTab) return

      scroller.scrollTo(`scrollElementSeason${activeIndex}`, {
        duration: 1100,
        smooth: isMobile ? false : true,
        offset: -60, // Scrolls to element + 60 pixels down the page
      })

      setSurpassWaypoint(true)
      setTimeout(() => {
        setSurpassWaypoint(false)
      }, 1500)
    },
    [isDraggingSeasonTab],
  )

  const handleEnterSeason = useCallback(
    (currentSeason: Season) => {
      if (surpassWaypoint) return

      const currentIndex = Object.keys(seasonSlide).find(
        (key) => seasonSlide[key as unknown as number] === currentSeason,
      ) as unknown as number

      if (swiperRef.current) {
        swiperRef.current.swiper.slideTo(currentIndex)
      }
    },
    [surpassWaypoint],
  )

  // Event card related functions
  const handleCardClick = useCallback((event: EventType) => {
    setModalEvent(event)
    if (modalRef1 && modalRef1.current && modalRef1.current.openModal)
      modalRef1.current.openModal()
  }, [])

  const handleCardClickLeft = useCallback(() => {
    const currentEventIndex = sortedFlatArrayData.findIndex(
      (data) => data._id === modalEvent?._id,
    )
    const nextIndex =
      currentEventIndex - 1 >= 0
        ? currentEventIndex - 1
        : sortedFlatArrayData.length - 1
    setModalEvent(sortedFlatArrayData[nextIndex])
  }, [modalEvent, sortedFlatArrayData])

  const handleCardClickRight = useCallback(() => {
    const currentEventIndex = sortedFlatArrayData.findIndex(
      (data) => data._id === modalEvent?._id,
    )
    const nextIndex =
      currentEventIndex + 1 < sortedFlatArrayData.length
        ? currentEventIndex + 1
        : 0
    setModalEvent(sortedFlatArrayData[nextIndex])
  }, [modalEvent, sortedFlatArrayData])

  return (
    <Layout tabNames={[]} meta={meta} footer={footer}>
      <Section>
        <Swiper
          ref={swiperRef}
          className="season-swiper-container"
          slidesPerView={'auto'}
          grabCursor={true}
          centeredSlides={true}
          slideToClickedSlide={true}
          initialSlide={0}
          onTouchStart={handleSwiperTouchStart}
          onTransitionEnd={handleSwiperTouchEnd}
          onSlideChange={handleSwiperSlideChange}>
          <Global
            styles={css(
              mq({
                '.season-swiper-container': {
                  width: '100%',
                  paddingTop: [20, 20],
                  paddingBottom: [10, 10],
                  borderBottom: 'solid 1px black',
                  position: 'fixed',
                  backgroundColor: colors.lightBrown,
                  zIndex: 2,
                },
                '.season-swiper-slide': {
                  width: ['310px', '260px'],
                },
              }),
            )}
          />

          {sortedArrayData &&
            sortedArrayData.map((season, index) => (
              <SwiperSlide
                className="season-swiper-slide"
                key={`seasonTab${index}`}>
                <SlideTitle isSelected={selectedSeasonIndex === season.index}>
                  {season.title}
                </SlideTitle>
              </SwiperSlide>
            ))}
        </Swiper>

        {ready ? (
          sortedArrayData &&
          sortedArrayData.map((season, index) => {
            return (
              <SeasonEvents
                key={`season${index}`}
                season={season}
                eventPosts={eventPosts}
                onCardClick={handleCardClick}
                onEnter={handleEnterSeason}
              />
            )
          })
        ) : (
          <div style={{ height: '100vh' }} />
        )}
      </Section>

      <Modal
        ref={modalRef1}
        onLeft={handleCardClickLeft}
        onRight={handleCardClickRight}>
        <ModalEvent
          {...modalEvent}
          onSwipedLeft={handleCardClickRight}
          onSwipedRight={handleCardClickLeft}
        />
      </Modal>
    </Layout>
  )
}

interface SeasonEventsProps {
  season: SeasonEventsSortedType
  eventPosts: EventPostType
  onCardClick: (event: EventType) => void
  onEnter: (season: Season) => void
}

const SeasonEvents: FC<SeasonEventsProps> = (
  { season, eventPosts, onCardClick, onEnter },
  ref,
) => {
  const handleEntered = useCallback(() => {
    onEnter(season.name)
  }, [onEnter])

  return (
    <Waypoint onEnter={handleEntered} topOffset={200}>
      <WaypointContainer>
        <ScrollElement name={`scrollElementSeason${season.index}`}>
          <SeasonTitle>{season.name}</SeasonTitle>
          {season.events &&
            season.events.map((events, index) => (
              <Fragment key={`events${index}`}>
                <MonthTitle>
                  {moment(events[0].startTime)
                    .tz(moment.tz.guess())
                    .format('MMMM')}
                </MonthTitle>
                <EventsContainer>
                  {events.map((data, i) => (
                    <EventContainer key={`card${i}`}>
                      <EventCardContainer>
                        <EventCard {...data} onClick={onCardClick} />
                      </EventCardContainer>
                      <EventLinkContainer>
                        {data._id && eventPosts[data._id] && (
                          <EventLink
                            to={`/archive/${
                              eventPosts[data._id].slug?.current
                            }`}>{`Explore ${
                            eventPosts[data._id].event?.type
                          }`}</EventLink>
                        )}
                      </EventLinkContainer>
                    </EventContainer>
                  ))}
                </EventsContainer>
              </Fragment>
            ))}
        </ScrollElement>
      </WaypointContainer>
    </Waypoint>
  )
}

const WaypointContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  scrollMarginTop: 60,
  borderTop: 'solid 1px',
})

const ScrollElement = styled(Element)({
  display: 'flex',
  flexDirection: 'column',
})

const Section = styled.div(
  {
    flex: '1 0 auto',
    display: 'flex',
    flexDirection: 'column',
  },
  mq({
    paddingBottom: spaces.large,
  }),
)

const SlideTitle = styled.div<{ isSelected?: boolean }>(
  typography.mediumSerif,
  mq({
    textAlign: 'center',
    fontSize: [48, 48],
  }),
  (props) => ({
    color: props.isSelected ? 'black' : 'rgba(0,0,0,0.2)',
  }),
)

const SeasonTitle = styled.h2(
  typography.largeSerif,
  mq({
    textAlign: 'center',
    marginTop: [200, 180],
    padding: '0 20px',
    width: [400, 300],
    marginRight: 'auto',
    marginLeft: 'auto',
    marginBottom: spaces.medium,
  }),
)

const MonthTitle = styled.div(
  typography.smallSans,
  mq({
    textAlign: 'center',
    marginBottom: spaces.medium,
    width: '100%',
    fontSize: [32, 40],
  }),
)

const EventsContainer = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  marginLeft: -GUTTER_SIZE,
  marginBottom: GUTTER_SIZE * 4,
  maxWidth: 1200,
  alignSelf: 'center',
})

const EventContainer = styled.div(
  {
    flexDirection: 'column',
  },
  mq({
    marginBottom: [GUTTER_SIZE * 2, 5],
  }),
)

const EventCardContainer = styled.div(
  {
    display: 'flex',
    marginLeft: GUTTER_SIZE,
    marginTop: GUTTER_SIZE,
  },
  mq({
    width: [360, 'calc(100vw - 30px)'],
    height: [360 * 1.4, 'calc((100vw - 30px) * 1.3)'],
  }),
)

const EventLinkContainer = styled('div')({
  height: 50,
  marginLeft: GUTTER_SIZE,
})

const EventLink = styled(InternalLinkButton)(
  {
    display: 'flex',
    backgroundColor: 'transparent',
    border: '1px solid black',
    color: 'black',
    margin: 30,
    whiteSpace: 'pre-wrap',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mq({
    fontSize: 20,
    padding: 8,
  }),
)

const EventCard: FC<EventType> = (data) => {
  const [title, typeTitle] = useMemo(() => {
    let eventName, eventType
    if (data.name && data.name.split(':').length > 1) {
      eventName = data.name.split(':')[1].trim()
      eventType = data.name.split(':')[0].toUpperCase()
    } else {
      eventName = data.name
      if (data.type === 'ambient' || data.type === 'journey') {
        eventType = 'PLACE'
      } else {
        eventType = 'PERFORMANCE'
      }
    }
    return [eventName, eventType]
  }, [data.name, data.type])

  const isSunset = typeTitle === 'SUNSET'

  const handleOnClick = useCallback(
    (e) => {
      e.preventDefault()
      if (data.onClick) {
        data.onClick(data)
      }
    },
    [data],
  )

  return (
    <CardContainer onClick={handleOnClick} eventType={typeTitle}>
      <CardTextContainer>
        <TypeImageContainer>
          {isSunset && <TypeImage src={sunsetIcon} alt={'sunset icon'} />}
          {typeTitle === 'PLACE' && (
            <TypeImage src={placesIcon} alt={'places icon'} />
          )}
        </TypeImageContainer>

        <TitleContainer>
          <CardTitle>
            <Truncate lines={2}>{data.name}</Truncate>
          </CardTitle>
        </TitleContainer>

        <LocationContainer>
          {data.location && (
            <>
              <LocationIconImage src={locationIcon} alt={'location icon'} />
              <CardText>{data.location}</CardText>
            </>
          )}
        </LocationContainer>

        <CardText>
          <Truncate
            lines={isMobile ? (isSunset ? 2 : 3) : isSunset ? 3 : 4}
            ellipsis={
              <span>
                ...{' '}
                <a href="" onClick={handleOnClick}>
                  Read more
                </a>
              </span>
            }>
            {data.description}
          </Truncate>
        </CardText>
      </CardTextContainer>
      <CardImageContainer isSunset={isSunset}>
        {data.image?.asset && data.image.asset.url && (
          <CardImage
            src={data.image.asset.url}
            srcSet={getSanitySrcSet(data.image.asset.url, 400)}
            sizes={'(max-width: 800px) 100%, 100%'}
          />
        )}
      </CardImageContainer>
    </CardContainer>
  )
}

interface ModalEventProps extends EventType {
  onSwipedLeft?: () => void
  onSwipedRight?: () => void
}

const ModalEvent: FC<ModalEventProps> = (data) => {
  const rawStartTime = moment(data.startTime).tz(moment.tz.guess())
  const date = rawStartTime.format('MMM D dddd')
  const startTime = rawStartTime.format('h:mma')
  const rawEndTime = moment(data.endTime).tz(moment.tz.guess())
  const endTime = rawEndTime.format('h:mma') + ' ' + rawEndTime.format('z')

  const [title, typeTitle] = useMemo(() => {
    let eventName, eventType
    if (data.name && data.name.split(':').length > 1) {
      eventName = data.name.split(':')[1].trim()
      eventType = data.name.split(':')[0].toUpperCase()
    } else {
      eventName = data.name
      if (data.type === 'ambient' || data.type === 'journey') {
        eventType = 'PLACE'
      } else {
        eventType = 'PERFORMANCE'
      }
    }
    return [eventName, eventType]
  }, [data.name, data.type])

  const handlers = useSwipeable({
    onSwipedLeft: () => data.onSwipedLeft && data.onSwipedLeft(),
    onSwipedRight: () => data.onSwipedRight && data.onSwipedRight(),
    preventDefaultTouchmoveEvent: true,
  })

  const isSunset = typeTitle === 'SUNSET'

  return (
    <Container type={typeTitle} {...handlers}>
      <EventTextContainer>
        <TypeImageContainer>
          {isSunset && <EventTypeImage src={sunsetIcon} alt={'sunset icon'} />}
          {typeTitle === 'PLACE' && (
            <EventTypeImage src={placesIcon} alt={'places icon'} />
          )}
        </TypeImageContainer>
        <TitleContainer>
          <CardTitle>{data.name}</CardTitle>
        </TitleContainer>

        <LocationContainer>
          {data.location && (
            <>
              <LocationIconImage src={locationIcon} alt={'location icon'} />
              <CardText>{data.location}</CardText>
            </>
          )}
        </LocationContainer>

        <TimeText>{`${date}\n${startTime}–${endTime}`}</TimeText>

        <CardText>{data.description}</CardText>
      </EventTextContainer>
      <ImageContainer>
        {data.image?.asset && data.image.asset.url && (
          <EventImage
            src={data.image.asset.url}
            srcSet={getSanitySrcSet(data.image.asset.url, 400)}
            sizes={'(max-width: 800px) 100%, 100%'}
          />
        )}
      </ImageContainer>
    </Container>
  )
}

const Container = styled.div<{ type?: string }>(
  {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflow: 'auto',
    borderRadius: 15,
  },
  (props) => ({
    backgroundColor:
      props.type === 'SUNSET'
        ? '#EEDCC2'
        : props.type === 'PLACE'
        ? '#EEE7D7'
        : '#F4EEE9',
  }),
)

const ImageContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

const EventImage = styled.img(
  {
    width: '100%',
    height: '100%',
    padding: 20,
    objectFit: 'contain',
  },
  mq({
    paddingTop: [40, 20],
  }),
)

const EventTextContainer = styled.div(
  {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  mq({
    paddingLeft: spaces.small,
    paddingRight: spaces.small,
  }),
)

const CardContainer = styled.div<{ eventType?: string }>(
  {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    borderRadius: 15,
    border: 'solid 1px black',
    cursor: 'pointer',
  },
  (props) => ({
    backgroundColor: backgroundColorByType(props.eventType),
  }),
)

const CardTextContainer = styled.div(
  {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  mq({
    paddingLeft: [30, 30],
    paddingRight: [30, 30],
    flex: [6, 4.5],
  }),
)

const TitleContainer = styled.div(
  {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  mq({
    padding: '15px 5px 0 5px',
    minHeight: [100, 90],
  }),
)

const CardTitle = styled.h3(
  typography.smallSerif,
  { whiteSpace: 'pre-wrap' },
  mq({
    textAlign: 'center',
    lineHeight: 1,
    fontSize: [32, 32],
  }),
)

const TimeText = styled.div(
  typography.tinyMono,
  { whiteSpace: 'pre-wrap' },
  mq({
    paddingBottom: [30, 20],
  }),
)

const CardText = styled.div(
  typography.tinyMono,
  mq({
    paddingBottom: [0, 0, 0],
  }),
)

const LocationContainer = styled.div(
  {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  mq({
    paddingBottom: spaces.small,
  }),
)

const TypeImageContainer = styled.div({
  position: 'relative',
})

const TypeImage = styled.img(
  {
    width: 25,
    position: 'absolute',
    top: 10,
  },
  mq({
    right: [-15, -20],
  }),
)

const EventTypeImage = styled.img(
  {
    width: 25,
    position: 'absolute',
  },
  mq({
    top: [50, 40],
    right: [-10, -5],
  }),
)

const LocationIconImage = styled.img({
  width: 12,
  height: 16,
  marginRight: 10,
  marginTop: 2,
})

const CardImageContainer = styled.div<{ isSunset?: boolean }>(
  {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  (props) => ({
    flex: props.isSunset ? 7 : 5,
  }),
)

const CardImage = styled.img({
  objectFit: 'cover',
  width: '100%',
  height: '100%',
  borderBottomLeftRadius: 15,
  borderBottomRightRadius: 15,
})

export default withPreview(Archive)

export const pageQuery = graphql`
  query Archive {
    prismicSplash {
      _previewable
      data {
        ...Meta
        ...Footer
      }
    }
    allSanityEventPost(sort: { fields: publishedAt, order: DESC }) {
      nodes {
        event {
          _id
          type
          startTime
          location
        }
        slug {
          current
        }
        name
        excerpt
        mainImage {
          asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            url
          }
        }
      }
    }
    winter2021Events: allSanityEvent(
      filter: {
        startTime: {
          gte: "2020-12-21T00:00:01.815Z"
          lt: "2021-03-20T00:00:01.815Z"
        }
      }
      sort: { order: DESC, fields: startTime }
    ) {
      nodes {
        startTime
        name
        description
        endTime
        id
        _id
        location
        type
        image {
          asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            url
          }
        }
      }
    }
    spring2021Events: allSanityEvent(
      filter: {
        startTime: {
          gte: "2021-03-20T00:00:01.815Z"
          lt: "2021-06-20T00:00:01.815Z"
        }
      }
      sort: { order: DESC, fields: startTime }
    ) {
      nodes {
        startTime
        name
        description
        endTime
        id
        _id
        location
        type
        image {
          asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            url
          }
        }
      }
    }
    summer2021Events: allSanityEvent(
      filter: {
        startTime: {
          gte: "2021-06-20T00:00:01.815Z"
          lt: "2021-09-22T00:00:01.815Z"
        }
      }
      sort: { order: DESC, fields: startTime }
    ) {
      nodes {
        startTime
        name
        description
        endTime
        id
        _id
        location
        type
        image {
          asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            url
          }
        }
      }
    }
    fall2021Events: allSanityEvent(
      filter: {
        startTime: {
          gte: "2021-09-22T00:00:01.815Z"
          lt: "2021-12-21T00:00:01.815Z"
        }
      }
      sort: { order: DESC, fields: startTime }
    ) {
      nodes {
        startTime
        name
        description
        endTime
        id
        _id
        location
        type
        image {
          asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            url
          }
        }
      }
    }
  }
`
