import moment from 'moment-timezone'
import { SanityEvent, Maybe, SanityImageAsset } from '../../graphql-types'
import { colors } from '../styles'

export enum Season {
  WINTER_2021 = 'Winter Season 2021',
  SPRING_2021 = 'Spring Season 2021',
  SUMMER_2021 = 'Summer Season 2021',
  FALL_2021 = 'Fall Season 2021',
  WINTER_2022 = 'Winter Season 2022',
}

export type EventType = Pick<
  SanityEvent,
  'startTime' | 'name' | 'description' | 'endTime' | 'location' | 'type' | '_id'
> & {
  image?: Maybe<{
    asset?: Maybe<Pick<SanityImageAsset, 'gatsbyImageData' | 'url'>>
  }>
  onClick?: (event: EventType) => void
}

export interface EventsByMonth {
  [K: string]: EventType[]
}

export const groupEventsByMonth = (events: EventType[]) => {
  let monthGroupedEvents: EventsByMonth = {}
  events.forEach((e) => {
    const month = moment(e.startTime).format('MMMM')
    monthGroupedEvents[month]
      ? monthGroupedEvents[month].push(e)
      : (monthGroupedEvents[month] = [e])
  })
  return monthGroupedEvents
}

export const groupEventsByMonthForSeason = (events: EventType[]) => {
  const groupedEvents = groupEventsByMonth(events)
  return Object.keys(groupedEvents).map((k) => groupedEvents![k])
}

export const backgroundColorByType = (eventType?: string | null) => {
  if (!eventType) return colors.lightBrown
  switch (eventType) {
    case 'SUNSET':
      return '#EEDCC2'

    case 'PLACE':
      return '#EEE7D7'

    default:
      return colors.lightBrown
  }
}

export const getSeasonFromMonth = (time: moment.Moment) => {
  const month = parseInt(time.format('M'))
  const date = parseInt(time.format('D'))
  if (month === 12 && date >= 21) {
    return 'WINTER'
  } else if (month > 9 || (month === 9 && date >= 22)) {
    return 'FALL'
  } else if (month > 6 || (month === 6 && date >= 20)) {
    return 'SUMMER'
  } else if (month > 3 || (month === 3 && date >= 20)) {
    return 'SPRING'
  } else {
    return 'WINTER'
  }
}

export const getSeasonFromTime = (time: moment.Moment) => {
  if (moment(time).isBetween('2020-12-21', '2021-03-20', undefined, '[)')) {
    return Season.WINTER_2021
  } else if (
    moment(time).isBetween('2021-03-20', '2021-06-20', undefined, '[)')
  ) {
    return Season.SPRING_2021
  } else if (
    moment(time).isBetween('2021-06-20', '2021-09-22', undefined, '[)')
  ) {
    return Season.SUMMER_2021
  } else if (
    moment(time).isBetween('2021-09-22', '2021-12-21', undefined, '[)')
  ) {
    return Season.FALL_2021
  } else if (
    moment(time).isBetween('2021-12-21', '2022-03-20', undefined, '[)')
  ) {
    return Season.FALL_2021
  } else {
    return undefined
  }
}
